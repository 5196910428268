import React from 'react'
import DefaultLayout from '../components/Layout/DefaultLayout'
import PrivacyPolicy from '../components/utils/PrivacyPolicy'

const PrivacyPage = () => (
  <DefaultLayout title="Privacy">
    <PrivacyPolicy />
  </DefaultLayout>
)

export default PrivacyPage
