import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  list-style: square;
  padding: 1em 0 1em 3em;
`
const Section = styled.section`
  h5 {
    font-weight: 600;
  }
`

const PrivacyPolicy = () => (
  <div>
    <Section>
      <h3>Privacy Policy Notice</h3>
      <p>
        The policy: This privacy policy notice is served by
        <br />
        <br />
        <strong>
          Milaco Engineering, <br />
          Unit 1 Barons Court, <br />
          Graceways, <br />
          Blackpool <br />
          FY4 5GP
        </strong>
        <br />
        <br /> under the website; <strong>www.milaco.co.uk</strong>. <br />
        <br />
        The purpose of this policy is to explain to you how we control, process, handle and protect your personal
        information through the business and while you browse or use this website. If you do not agree to the following
        policy you may wish to cease viewing / using this website, and or refrain from submitting your personal data to
        us.
      </p>
    </Section>
    <Section>
      <h5>Policy key definitions:</h5>
      <List>
        <li>"I", "our", "us", or "we" refer to the business, Milaco Engineering Ltd Trading as Milaco.</li>
        <li>"you", "the user" refer to the person(s) using this website www.milaco.co.uk.</li>
        <li>GDPR means General Data Protection Act.</li>
        <li>PECR means Privacy & Electronic Communications Regulation.</li>
        <li>ICO means Information Commissioner's Office.</li>
        <li>Cookies mean small files stored on a users computer or device</li>
      </List>
    </Section>
    <Section>
      <h5>Key principles of GDPR:</h5>
      <p>
        Our privacy policy embodies the following key principles; (a) Lawfulness, fairness and transparency, (b) Purpose
        limitation, (c) Data minimisation, (d) Accuracy, (e) Storage limitation, (f) Integrity and confidence, (g)
        Accountability.
      </p>
    </Section>
    <Section>
      <h5>Processing of your personal data</h5>
      <p>
        Under the GDPR (General Data Protection Regulation) we control and / or process any personal information about
        you electronically using the following lawful bases.
      </p>
      <p>
        <strong>(a) Necessary for entering into, or performing, a contract</strong> – in order to perform obligations
        that we undertake in providing a service to you, or in order to take steps at your request to enter into a
        contract with us, it will be necessary for us to process your personal data;{' '}
      </p>
      <p>
        <strong>(b) Necessary for compliance with a legal obligation</strong> – we are subject to certain legal
        requirements which may require us to process your personal data. We may also be obliged by law to disclose your
        personal data to a regulatory body or law enforcement agency;
      </p>
      <p>
        <strong>(c) Necessary for the purposes of legitimate interests</strong> - either we, or a third party, will need
        to process your personal data for the purposes of our (or a third party's) legitimate interests, provided we
        have established that those interests are not overridden by your rights and freedoms, including your right to
        have your personal data protected. Our legitimate interests include responding to requests and enquiries from
        you or a third party, optimising our website and customer experience, informing you about our products and
        services and ensuring that our operations are conducted in an appropriate and efficient manner;
      </p>
      <p>
        <strong>(d) Consent</strong> – in some circumstances, we may ask for your consent to process your personal data
        in a particular way.{' '}
      </p>
    </Section>
    <Section>
      <h5>Your individual rights</h5>
      <p>Under the GDPR your rights are as follows. You can read more about your rights in details here;</p>
      <List>
        <li>the right to be informed;</li>
        <li>the right of access;</li>
        <li>the right to rectification;</li>
        <li>the right to erasure;</li>
        <li>the right to restrict processing;</li>
        <li>the right to data portability;</li>
        <li>the right to object; and</li>
        <li>the right not to be subject to automated decision-making including profiling.</li>
      </List>
      <p>
        You also have the right to complain to the ICO <a href="https://www.ico.org.uk">www.ico.org.uk</a> if you feel
        there is a problem with the way we are handling your data.
      </p>
      <p>We handle subject access requests in accordance with the GDPR.</p>
    </Section>
    <Section>
      <h5>Internet cookies</h5>
      <p>
        We use cookies on this website to provide you with a better user experience. We do this by placing a small text
        file on your device / computer hard drive to track how you use the website, to record or log whether you have
        seen particular messages that we display, to keep you logged into the website where applicable, to display
        relevant adverts or content, referred you to a third party website.
      </p>
      <p>
        Some cookies are required to enjoy and use the full functionality of this website. We use a cookie control
        system which allows you to accept the use of cookies, and control which cookies are saved to your device /
        computer. Some cookies will be saved for specific time periods, where others may last indefinitely. Your web
        browser should provide you with the controls to manage and delete cookies from your device, please see your web
        browser options.
      </p>
      <p>Cookies that we use are;</p>
      <List>
        <li>
          <strong>Session Cookies</strong> - These cookies do not hold personal information. Session Cookies hold
          increpted keys which allows us to establish if a user is verified to access certian areas of our site.
        </li>
        <li>
          <strong>Google Analytics</strong> - In order to improve our site we use Google Analytics to help monitor how
          users interact with our content. The information we share with Google includes the URL of the page that you’re
          visiting and your IP address. Google uses the information shared by sites and apps to deliver their services,
          maintain and improve them, develop new services, measure the effectiveness of advertising, protect against
          fraud and abuse and personalise content and ads that you see on Google and on their partners’ sites and apps.
          See thier Privacy Policy to learn more about how they process data for each of these purposes. information.
        </li>
      </List>
    </Section>
    <Section>
      <h5>Data security and protection</h5>
      <p>
        We ensure the security of any personal information we hold by using secure data storage technologies and precise
        procedures in how we store, access and manage that information. Our methods meet the GDPR compliance
        requirement.
      </p>
    </Section>
    <Section>
      <h5>Fair & Transparent Privacy Explained</h5>
      <p>
        We have provided some further explanations about user privacy and the way we use this website to help promote a
        transparent and honest user privacy methodology.
      </p>
    </Section>
    <Section>
      <h5>Email marketing messages & subscription</h5>
      <p>
        Under the GDPR we use the consent lawful basis for anyone subscribing to our newsletter or marketing mailing
        list. We only collect certain data about you, as detailed in the "Processing of your personal data" above. Any
        email marketing messages we send are done so through an EMS, email marketing service provider. An EMS is a third
        party service provider of software / applications that allows marketers to send out email marketing campaigns to
        a list of users.
      </p>
      <p>
        Email marketing messages that we send may contain tracking beacons / tracked clickable links or similar server
        technologies in order to track subscriber activity within email marketing messages. Where used, such marketing
        messages may record a range of data such as; times, dates, I.P addresses, opens, clicks, forwards, geographic
        and demographic data. Such data, within its limitations will show the activity each subscriber made for that
        email campaign.
      </p>
      <p>
        Any email marketing messages we send are in accordance with the GDPR and the PECR. We provide you with an easy
        method to withdraw your consent (unsubscribe) or manage your preferences / the information we hold about you at
        any time. See any marketing messages for instructions on how to unsubscribe or manage your preferences, you can
        also unsubscribe from all MailChimp lists, by following this link, otherwise contact the EMS provider.
      </p>
      <p>Our EMS provider is; MailChimp. We hold the following information about you within our EMS system;</p>
      <List>
        <li>Email address</li>
        <li>I.P address</li>
        <li>Subscription time & date</li>
        <li>Full name</li>
        <li>Company you work for</li>
        <li>Address of Company</li>
        <li>Type of Company/Industry you work in</li>
        <li>Your Job Title</li>
      </List>
    </Section>
    <Section>
      <h5>Changes to our Privacy Policy</h5>
      <p>
        This privacy policy can be changed by Milaco at any time. If we change our privacy policy in the future, we will
        advise you of material changes or updates to our privacy policy by email, where you have provided us with your
        email address.{' '}
      </p>
    </Section>
  </div>
)

export default PrivacyPolicy
